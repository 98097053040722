import React, { useState, useEffect } from "react";
import Image from "next/image";
import logoImage from "../public/logo.png";
import logoImageDark from "../public/aptofylogodark.png";
import NextLink from "next/link";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  useColorMode,
  chakra,
  shouldForwardProp,
  Icon,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  SunIcon,
  MoonIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { useRouter } from "next/router";
import { useLoginData } from "../context/context";
import { isValidMotionProp, motion } from "framer-motion";
// import CertificateVerifyModal from "../pages/certificateverfication";
const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const HeaderPage = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const router = useRouter();
  const { userId, setUserId, userType, setUserType } = useLoginData();
  // const [isCertificateVerifyModalOpen, setIsCertificateVerifyModalOpen] = useState(false);

  const { isOpen, onToggle } = useDisclosure();

  useEffect(() => {
    const storedUserId = sessionStorage.getItem("userId");
    const storedUserType = sessionStorage.getItem("userType");
    if (storedUserId) setUserId(JSON.parse(storedUserId));
    if (storedUserType) setUserType(JSON.parse(storedUserType));
  }, []);

  const navigateToProfile = () => {
    if (userType === "recruiter") {
      router.push("/rectruiter");
    } else if (userType === "candidate") {
      router.push("/userProfile");
    } else if (userType === "sales_manager") {
      router.push("/salesManager");
    } else if (userType === "candidate_manager") {
      router.push("/candidateManager");
    }
    // else if (userType === "recruitment_manager") {
    //   router.push("/recruiterManager");
    // }
    else if (userType === "recruitment_manager") {
      router.push("/accountManager");
    } else if (userType === "admin") {
      router.push("/admin");
    }
  };

  const logoutHandler = () => {
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("userType");
    setUserId(null);
    setUserType(null);
    router.push("/");
  };

  const handleLogoClick = () => {
    console.log("Logo clicked"); // Debug log
    router.push("/").catch((error) => {
      console.error("Navigation error:", error);
    });
  };

  return (
    <Box>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex
          flex={{ base: 1 }}
          justify={{ base: "center", md: "start" }}
          align={"center"}
        >
          <Box
            cursor="pointer"
            onClick={(e) => {
              e.stopPropagation();
              handleLogoClick();
            }}
          >
            <Image
              src={colorMode === "light" ? logoImage : logoImageDark}
              alt="Logo"
              width={160}
              height={"auto"}
            />
          </Box>

          <Flex
            display={{ base: "none", md: "flex" }}
            ml={10}
            marginLeft="250px"
          >
            <DesktopNav />
          </Flex>
        </Flex>
        {/* <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="xl"
          marginRight={15}
         >
          <ChakraBox
            // animate={{
            //   scale: [1, 1, 1.1, 1, 1],
            // }}
            // transition={{
            //   duration: 3,
            //   ease: "easeInOut",
            //   repeat: Infinity,
            //   repeatType: "loop",
            // }}
            padding="2"
            bg={"#3B58D7"}
            color="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            onClick={() => setIsCertificateVerifyModalOpen(true)}
            cursor={'pointer'}
            borderRadius={'8'}
          >
            Certificate Verification
          </ChakraBox>
        </Box> */}

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          <IconButton aria-label="Toggle Mode" onClick={toggleColorMode}>
            {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
          </IconButton>
          {userId ? (
            <>
              <Button
                color="#3B58D7"
                fontSize={"sm"}
                fontWeight={600}
                onClick={navigateToProfile}
              >
                Profile
              </Button>
              <Button
                color="#3B58D7"
                fontSize={"sm"}
                fontWeight={600}
                onClick={(e) => logoutHandler(e)}
              >
                Sign out
              </Button>
            </>
          ) : (
            <>
              <Button
                as={NextLink}
                fontSize={"sm"}
                fontWeight={400}
                variant={"link"}
                href={"/login"}
                bg="#3B58D7"
                color="white"
              >
                Login
              </Button>
              <Button
                as={NextLink}
                fontSize={"sm"}
                fontWeight={400}
                color={"white"}
                bg={"#3B58D7"}
                _hover={{
                  bg: "green.300",
                }}
                href={"/signUp"}
              >
                SignUp
              </Button>
            </>
          )}
        </Stack>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
      {/* {isCertificateVerifyModalOpen ? (
        <CertificateVerifyModal
          isCertificateVerifyModalOpen={isCertificateVerifyModalOpen}
          setIsCertificateVerifyModalOpen={setIsCertificateVerifyModalOpen}
        />
      ) : null} */}
    </Box>
  );
};

const DesktopNav = () => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction={"row"} spacing={4}>
      <Box key="HomeLink">
        <Popover trigger={"hover"} placement={"bottom-start"}>
          <PopoverTrigger>
            <Link
              as={NextLink}
              p={2}
              href={"/"}
              fontSize={"sm"}
              fontWeight={500}
              color={linkColor}
              _hover={{
                textDecoration: "none",
                color: linkHoverColor,
              }}
            >
              Home
            </Link>
          </PopoverTrigger>
        </Popover>
      </Box>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                as={NextLink}
                p={2}
                href={navItem.href ?? "#"}
                fontSize={"sm"}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Link
      href={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "pink.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} as={NextLink} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: "About Us",
    href: "/AboutUs",
  },
  {
    label: "Offerings",
    href: "/services",
  },
  {
    label: "Insights",
    children: [
      {
        label: "Case Study",
        href: "/caseStudy",
      },
      {
        label: "Articles",
        href: "/Articles",
      },
      {
        label: "Blogs",
        href: "https://unlimiteye.wordpress.com/",
      },
      {
        label: "Podcasts",
        href: "podcasts",
      },
    ],
  },
  {
    label: "Contact Us",
    href: "/contactPage",
  },
  {
    label: "Help",
    href: "/Faq",
  },
  {
    label: "Career",
    href: "/career",
  },
  {
    label: "Tools",
    href: "/tools",
  },
];

export default HeaderPage;
